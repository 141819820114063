<template>
    <v-app-bar
        flat
        dense
        class="app-bar-container"
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        dark
        color="primary"
        app>
        <template v-slot:img="{ props }">
            <v-img
                v-bind="props"
                color="#3B2747" />
        </template>

        <!-- TODO: Switch to v-navigation-drawer for mobile -->
        
        <img src="@/assets/logo.svg" height="100%">
        <v-spacer />
        <the-app-bar-menu />
        <v-spacer />
        <the-app-bar-buttons />
    </v-app-bar>
</template>

<script>

export default {
    components: {
        theAppBarButtons: () => import("@/components/TheAppBarButtons"),
        theAppBarMenu: () => import("@/components/TheAppBarMenu")
    }
}
</script>
